@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .zoom-out {
    zoom: 0.7;
  }
}

* {
  margin: 0;
  padding: 0;
  padding-block: 0;
  padding-inline: 0;
}
p {
  margin: 0;
  padding: 0;
  line-height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.shadow-1 {
  box-shadow: rgba(50, 50, 93, 0.3) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.button-new {
  position: relative;
  background: linear-gradient(150deg, #f4d803, #f9e971, #ffea2e, #f9e971);
  background-size: 400%;

  animate: 0.5s;
  transition: 0.5s;
  overflow: hidden;
}
.button-new::before {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: linear-gradient(90deg, #ffffff4d, transparent);
  animation: left-to-right 1s linear infinite;
  rotate: -45deg;
}
@keyframes left-to-right {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.item {
  display: inline-block;
  margin-right: 10px;
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  background-color: #f0f0f0;
  line-height: 100px; /* Center content vertically */
  text-align: center; /* Center content horizontally */
  animation: scrollAnimation 10s linear infinite;
}

/* Keyframe animation */
@keyframes scrollAnimation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.swiper-wrapper {
  display: flex;
  align-items: center !important;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0px !important;
  top: 90% !important;
  left: 0;
  width: 100%;
}
.swiper-pagination-bullet {
  transition: all 0.4s ease !important;
  height: 9px !important;
}
.swiper-pagination-bullet-active {
  width: 30px !important;
  border-radius: 10px !important;
  transition: all 0.4s ease;
}
.text-underline {
  text-decoration: underline;
  text-underline-offset: 10%;
}

.design-banner-bg {
  background-image: url('assets/Images/Design/banner.png');
  background-size: cover;
  filter: opacity(0.9);
  filter: brightness(0.5);
  filter: contrast(0.9);
  background-color: rgba(0, 0, 0, 0.3);
}
.card {
  background-color: white;
  border-radius: 0;
  color: black;
  border: none !important ;
  box-shadow: none;

  width: 100%;
  text-shadow: none;
  --bs-card-border-width: 0px;
}

.card-header button {
  width: 90%;
  display: flex;
  gap: 1px;
  align-items: center;
  padding: 1rem;
}
.transition-all {
  transition: all 0.4s ease;
}
.nav-items {
  animation: growDown 500ms ease-in-out forwards;
  transform-origin: top center;
  transition: transform 0.5s ease-in-out;
}
.nav-items-shrink {
  animation: shrink 500ms ease-in-out backwards;
  transform-origin: top center;
}

@keyframes shrink {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(1);
  }
}

.overflow-y {
  overflow-y: scroll;
}
.shadow-2 {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
@media (min-width: 768px) {
  .overflow-y {
    overflow-y: hidden !important;
  }
}
.overflow-x {
  overflow-x: scroll;
}

@media (min-width: 768px) {
  .overflow-x {
    overflow-x: unset !important;
  }
}

.text-gradient {
  /** TEXT GRADIENT */
  color: #d65685;
  background-image: -webkit-linear-gradient(
    45deg,
    #d65685 45%,
    #b066b0 100%,
    #f481fc 43%
  );
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}
.scroll-container {
  display: flex;
  overflow-x: auto;
  align-items: center;
  white-space: nowrap;
  width: 100%;
}

.scroll-content {
  display: inline-flex;
  align-items: center;
}

.item {
  display: inline-block;
  width: 200px;
  height: 100%;
  background-color: transparent;
  color: white;
  text-align: center;
  line-height: 200px;
  margin: 0 10px;
  white-space: normal;
}
